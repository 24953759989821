.Apphead{

    background-color: black;
    min-height: 100vh;
  
}


.btn2 {
    position: absolute;
    top: 460px;
    padding: 15px;
    text-transform: uppercase;
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    color: black;
    background-color: white;
    border-width:0;
  }

.btn2:hover,
.btn2:focus,
.btn2:active,
.btn2.active,
.open .dropdown-toggle.btn2 {
  color: white;
  background-color: black;
  
}

.btn2.hover,
.light{
    position:absolute;
    top:265px;
    width:100px;
  }

  .App-headers {
    display: flex;
    
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 4vmin);
    
  }

 .text{
  position:absolute;
  content: fit;
  top: 0px;
  text-align: center;
  font-size: large;
  color:white;
  font-weight: bold;
 }
 .text2{
  content: fit;
  margin:75px;
  font-size: small;
  color:white;
  font-weight: bold;
  margin-left: 400px;
  margin-right:400px;
 }
 .text3{
  font-size: medium;
  color:white;
  font-weight: bold;
 }


 

 .grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); /* Create a flexible grid with 200px columns */
  gap: 20px; /* Add some gap between grid items */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  
}

.page-center {
  text-align: center;
  display: flex;

  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */}

.image-box {
  text-align: center; /* Center the images horizontally */
  border: 1px solid #ccc; /* Add a border for image boxes (optional) */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */

}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
  height: 0%;
}


img {
  
  max-width: 100%; /* Ensure the images don't exceed the container width */
  height: auto; /* Maintain the image aspect ratio */
  display: block; /* Remove any default inline spacing */
}
